<template>
  <div class="Rank">
    <div>
      <Navbar></Navbar>
    </div>
    <br /><br /><br /><br />
    <v-overlay :value="isloading">
      <v-progress-circular
      indeterminate
        size="100"
        width="7"
        color="green"
      ></v-progress-circular>
    </v-overlay>
      <!-- รายละเอียดการอัพแรงค์  -->
      <v-row>
        <v-col>
          <div class="con">
            <div class="sub-con" >
                <div class="head"><h1>เงื่อนไขการเลื่อนขั้นแรงค์ </h1></div>
                <div class="c-red"> **1 บาท = 10 แต้มบุญ**</div>
                <div class="small-contentx"><img src="../../public/image/rank/rank-diamond.png" alt="Diamond"><span class="rank6">Daimond </span><span class="c-white"> : 100000 แต้มขึ้นไป</span></div>
                <div class="small-contentx"><img src="../../public/image/rank/rank-platinum.png" alt="Platinum"><span class="rank5">Platinum </span><span class="c-white"> : 30000-99999 แต้ม</span></div>
                <div class="small-contentx"><img src="../../public/image/rank/rank-gold.png" alt="Gold"><span class="rank4">Gold</span><span class="c-white"> : 10000-29999 แต้ม</span></div>
                <div class="small-contentx"><img src="../../public/image/rank/rank-silver.png" alt="Silver"><span class="rank3">Sliver </span><span class="c-white"> : 5000-9999 แต้ม</span></div>
                <div class="small-contentx"><img src="../../public/image/rank/rank-bronze.png" alt="Bronze"><span class="rank2">Bronze </span><span class="c-white"> : 0-4999 แต้ม</span></div>
                <div class="small-contentx"><img src="../../public/image/rank/rank-beginner.png" alt="Beginner"><span class="rank">Beginner </span><span class="c-white"> : ไม่เคยบริจาค</span></div>
                
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- รายละเอียดการอัพแรงค์  -->
    <v-container>
      <!-- tablist -->
      <v-card color="basil">
        <v-card-title class="text-center justify-center py-6">
          <h1 >
            ตารางอันดับ
          </h1>
        </v-card-title>

        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab>
            Beginner
            <img src="../../public/image/rank/rank-beginner.png" alt="Beginner">
          </v-tab>
          <v-tab>
            Bronze
            <img src="../../public/image/rank/rank-bronze.png" alt="Bronze">
          </v-tab>
          <v-tab>
            Silver
            <img src="../../public/image/rank/rank-silver.png" alt="Silver">
          </v-tab>
          <v-tab>
            Gold
            <img src="../../public/image/rank/rank-gold.png" alt="Gold">
          </v-tab>
          <v-tab>
            Platinum
            <img src="../../public/image/rank/rank-platinum.png" alt="Platinum">
          </v-tab>
          <v-tab>
            Diamond
            <img src="../../public/image/rank/rank-diamond.png" alt="Diamond">
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in head" :key="item">
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="filteredList"
                :search="search"
              >
                <template filteredList.fullname="{ User }"
                  >{{ filteredList.firstname }}
                  {{ filteredList.lastname }}</template
                >
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <br /><br /><br /><br /><br />
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
const Footer = () => import("@/components/navbar/footer");
const Navbar = () => import("@/components/navbar/navbar");
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      User: [],
      search: "",
      tab: 0,
      isloading: true,

      headers: [
        { text: "ลำดับ", value: "Rank" },
        { text: "ชื่อผู้บริจาค", value: "firstname" },
        { text: "Point", value: "totalpoint" },
      ],

      head: ["Beginner", "Bronze", "Silver", "Gold", "Platinum", "Diamond"],
    };
  },
  mounted: async function mounted() {
    await this.$http
      .get("/user/RankList")
      .then((res) => {
        this.User = res.data;
        this.isloading = false;
        console.log(res.data);
      })
      .catch(function(err) {
        console.log(err);
      });
    await this.onbeforeunload();
  },
  computed: {
    filteredList() {
      console.log(this.tab);
      var newlist = this.User.filter((user) => {
        var result = user.Rank.toLowerCase().includes(
          this.head[this.tab].toLowerCase()
        );
        console.log("kuy");
        return result;
      });
      return newlist;
    },
  },
  methods: {
    onbeforeunload() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.con{
   display: flex;
   justify-content: center;
  
}
.con > .sub-con{
  background-color: white;
   display: inline-block;
   box-shadow: 0 0 20px 0px rgb(0,0,0,0.2);
   border-radius: 20px;
   padding: 15px 15px;
}

.small-contentx > .rank{
  color: rgb(117, 117, 117);
}
.small-contentx > .rank2{
  color:rgb(138, 127, 65) ;
}
.small-contentx > .rank3{
  color:silver ;
}
.small-contentx > .rank4{
  color: gold;
  
}
.small-contentx > .rank5{
  color: #949494;
}
.small-contentx > .rank6{
  color:pink ;
  display: inline-block;
}
/* .small-content > .c-white{
  color: white;
} */
.head{
  display: flex;
  justify-content: flex-start;
  /* width: 40%; */
  /* padding: 10px 10px; */
}
.sub-con > .c-red{
  margin-bottom: 8px;
  color: red;
}
.head > h1{
  font-size: 30px;
  /* color: rgb(0, 0, 0); */
}
  img {
  width: 50px;
  height: 30px;
}
</style>